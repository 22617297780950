import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import SlotMachine from '..';
import { ISongs } from '../../config';
import { ChallengeResult, EventTypes, GameMode } from '../../global.d';
import { setBetAmount, setCurrency, setCurrentFreeSpinsTotalWin } from '../../gql';
import i18n from '../../i18next';
import { formatNumber, normalizeCoins, showCurrency } from '../../utils';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import { BgSkin } from '../background/background';
import {
  CHALLENGE_GAME_NINJA_ATTACK_DELAY,
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  eventManager,
} from '../config';
import {
  FREE_SPINS_COUNTER_POS_X,
  FREE_SPINS_TITLE_MOVE_SCALE,
  FREE_SPINS_TITLE_MOVE_SPIN_SCALE,
} from '../gameView/config';
import ReelsFrame from '../reels/frame/reelsFrame';

import { NinjaAttackTypes, NinjaIndex } from './config';
import { coinStyle, freeSpinsStyle, freeSpinsTextStyle } from './textStyles';

type NinjaAttackAnimationType = Record<NinjaAttackTypes, { animationName: string[] }>;

export const NinjaAttackAnimation: NinjaAttackAnimationType = {
  Ninja_Failed: {
    animationName: ['Cutin_Failed'],
  },
  Ninja_Success: {
    animationName: ['Cutin_Success', 'Cutin_Failed', 'Cutin_Failed_loop'],
  },
  Ninja_SuccessFreeSpin: {
    animationName: ['Cutin_Success', 'Cutin_Success_loop', 'Cutin_GetFreespin'],
  },
  Ninja_GetA: {
    animationName: ['Cutin_Success', 'Cutin_Success_in_GetNinjaA', 'Cutin_GetNinjaA'],
  },
  Ninja_GetB: {
    animationName: ['Cutin_Success', 'Cutin_Success_in_GetNinjaB', 'Cutin_GetNinjaB'],
  },
  Ninja_GetC: {
    animationName: ['Cutin_Success', 'Cutin_Success_in_GetNinjaC', 'Cutin_GetNinjaC'],
  },
};

class NinjaAttack extends PIXI.Container {
  private cutinBaseAnimation: SpineAnimation | undefined;

  private ambientNinjaAttackIntroAnimation: SpineAnimation | undefined;

  private ambientNinjaAttackAnimation: SpineAnimation | undefined;

  private ninjaAnimation: string;

  private ninjaAttackType: NinjaAttackTypes;

  private ninjaAttackIndex: number;

  private targetNinja: NinjaIndex;

  private resultNum: number;

  private resultText: PIXI.Text;

  private resultSpinText: PIXI.Text;

  public frame: ReelsFrame;

  public cutinBaseAnimPlayNum = 0;

  public setResultFlg = false;

  constructor() {
    super();
    this.ninjaAttackType = NinjaAttackTypes.NINJA_GET_A;
    this.initCutinBaseAnimation();
    this.initNinjaAttackIntroAnimation();
    this.initNinjaAttackAnimation();
    this.ninjaAttackIndex = 0;
    this.resultNum = 0;
    this.targetNinja = NinjaIndex.NINJA_1;
    this.ninjaAnimation = NinjaAttackAnimation[this.ninjaAttackType].animationName[this.ninjaAttackIndex];

    this.resultText = new PIXI.Text('100', coinStyle);
    this.resultText.resolution = 1;
    this.initResultText();
    this.resultSpinText = new PIXI.Text('100', coinStyle);
    this.resultSpinText.resolution = 1;
    this.initResultSpinText();

    this.addChild(this.cutinBaseAnimation!.spine);

    this.frame = new ReelsFrame();
    this.addChild(this.frame);

    this.addChild(this.ambientNinjaAttackIntroAnimation!.spine);
    this.addChild(this.ambientNinjaAttackAnimation!.spine);
    this.addChild(this.resultText);
    this.addChild(this.resultSpinText);

    eventManager.on(EventTypes.CHALLENGE_NINJA_CLICK, (index: NinjaIndex) => {
      this.setResultFlg = true;
      this.ninjaAttackStart(index);
    });

    eventManager.addListener(EventTypes.CHALLENGE_NINJA_SPIN, this.ninjaAttack.bind(this));

    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onChangeMode.bind(this));
  }

  public initResultText(): void {
    this.resultText.anchor.set(0.5);
    this.resultText.position.set(1000, GAME_CONTAINER_HEIGHT / 2);
    this.resultText.visible = false;
  }

  public initResultSpinText(): void {
    this.resultSpinText.anchor.set(0.5);
    this.resultSpinText.position.set(this.resultText.position.x, this.resultText.position.y + 70);
    this.resultSpinText.visible = false;
  }

  public setResult(result: ChallengeResult, num: number): void {
    switch (result) {
      case ChallengeResult.NINJA_A:
        this.ninjaAttackType = NinjaAttackTypes.NINJA_GET_A;
        break;
      case ChallengeResult.NINJA_B:
        this.ninjaAttackType = NinjaAttackTypes.NINJA_GET_B;
        break;
      case ChallengeResult.NINJA_C:
        this.ninjaAttackType = NinjaAttackTypes.NINJA_GET_C;
        break;
      case ChallengeResult.FREE_SPIN:
        this.ninjaAttackType = NinjaAttackTypes.NINJA_SUCCESS_FREE_SPIN;
        break;
      case ChallengeResult.COINS:
        const magnification = num / setBetAmount();

        if (magnification >= 1) {
          this.ninjaAttackType = NinjaAttackTypes.NINJA_SUCCESS;
        } else {
          this.ninjaAttackType = NinjaAttackTypes.NINJA_FAILED;
        }
        break;
      default:
        break;
    }
    this.resultNum = num;
  }

  private initCutinBaseAnimation(): void {
    this.cutinBaseAnimation = new SpineAnimation({}, PIXI.Loader.shared.resources.Cutin_Base.spineData!);
    this.cutinBaseAnimation!.addOnComplete(() => {
      this.cutinBaseAnimation!.setAnimation('Cutin_Base_loop', false);
      this.cutinBaseAnimation!.start();
    });
    this.cutinBaseAnimation!.getSpine().pivot.set(0.5, 0.5);
    this.cutinBaseAnimation?.getSpine().position.set(GAME_CONTAINER_WIDTH / 2, GAME_CONTAINER_HEIGHT / 2);
  }

  private initNinjaAttackIntroAnimation(): void {
    this.ambientNinjaAttackIntroAnimation = new SpineAnimation(
      {},
      PIXI.Loader.shared.resources.Cutin_intro_animation.spineData!,
    );
    this.ambientNinjaAttackIntroAnimation!.addOnComplete(() => {
      this.cutinBaseAnimPlayNum += 1;
      if (!this.setResultFlg) {
        this.ambientNinjaAttackIntroAnimation!.setAnimation('Cutin_Intro_loop', false);
      } else {
        this.ambientNinjaAttackIntroAnimation!.end();
        this.ambientNinjaAttackIntroAnimation!.getSpine().visible = false;
        this.ninjaAttackStart(0);
      }
    });

    this.ambientNinjaAttackIntroAnimation!.getSpine().pivot.set(0.5, 0.5);
    this.ambientNinjaAttackIntroAnimation?.getSpine().position.set(GAME_CONTAINER_WIDTH / 2, GAME_CONTAINER_HEIGHT / 2);
  }

  private initNinjaAttackAnimation(): void {
    this.ambientNinjaAttackAnimation = new SpineAnimation({}, PIXI.Loader.shared.resources.Cutin_animation.spineData!);
    this.ambientNinjaAttackAnimation!.addOnComplete(() => {
      if (this.ninjaAnimation === NinjaAttackAnimation[this.ninjaAttackType].animationName[this.ninjaAttackIndex]) {
        if (NinjaAttackAnimation[this.ninjaAttackType].animationName.length - 1 > this.ninjaAttackIndex) {
          this.ninjaAttackIndex += 1;
          this.ninjaAnimation = NinjaAttackAnimation[this.ninjaAttackType].animationName[this.ninjaAttackIndex];
          this.ambientNinjaAttackAnimation!.setAnimation(this.ninjaAnimation, false);
          this.ambientNinjaAttackAnimation!.start();

          const playSongs = this.getPlaySongs(this.ninjaAttackType);

          if (this.ninjaAttackIndex === 1) {
            AudioHowl.play({
              type: playSongs,
              stopPrev: false,
            });
          }

          if (this.resultNum > 0 && this.ninjaAttackIndex === 1) {
            this.initResultText();
            this.initResultSpinText();
            setTimeout(() => {
              if (this.ninjaAttackType === NinjaAttackTypes.NINJA_SUCCESS_FREE_SPIN) {
                this.resultText.text = `+${this.resultNum.toString()}`;
                this.resultText.style = freeSpinsStyle;
                setTimeout(() => {
                  this.addFreeSpinsTextMove();
                }, 300);

                if (this.resultNum === 1) {
                  this.resultSpinText.text = i18n.t('challengeModeFreeSpin') as string;
                } else {
                  this.resultSpinText.text = i18n.t('challengeModeFreeSpins') as string;
                }
                this.resultSpinText.style = freeSpinsTextStyle;
                this.resultSpinText.visible = true;
              } else {
                this.resultText.text = formatNumber(
                  setCurrency(),
                  normalizeCoins(this.resultNum),
                  showCurrency(setCurrency()),
                );

                this.resultText.style = coinStyle;
                setCurrentFreeSpinsTotalWin(setCurrentFreeSpinsTotalWin() + this.resultNum);
                eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setCurrentFreeSpinsTotalWin());
              }
              this.resultText.visible = true;
            }, 100);
          }
        } else {
          this.ambientNinjaAttackAnimation!.end();
          this.ambientNinjaAttackAnimation!.getSpine().visible = false;

          this.cutinBaseAnimation!.end();
          this.cutinBaseAnimation!.getSpine().visible = false;

          this.resultText.visible = false;
          this.resultSpinText.visible = false;

          eventManager.emit(EventTypes.CHALLENGE_NINJA_RESULT_LOOP, this.targetNinja);
        }
      }
    });
    this.ambientNinjaAttackAnimation!.getSpine().pivot.set(0.5, 0.5);
    this.ambientNinjaAttackAnimation?.getSpine().position.set(GAME_CONTAINER_WIDTH / 2, GAME_CONTAINER_HEIGHT / 2);
  }

  private addFreeSpinsTextMove(): void {
    const freeSpinTextPosX =
      FREE_SPINS_COUNTER_POS_X + SlotMachine.getInstance().gameView.gameTitle.challengeGameContainer.getSpinsTextPos();

    const animationChain: AnimationChain = new AnimationChain();
    const addFreeSpinsAnimationGroup = new AnimationGroup({});
    const addFreeSpinsResultTextY = this.getFreeSpinResultTextYAnimation(100);
    const addFreeSpinsResultTextX = this.getFreeSpinResultTextXAnimation(100, freeSpinTextPosX);
    const addFreeSpinsResultSpinTextY = this.getFreeSpinResultSpinTextYAnimation(100);
    const addFreeSpinsResultSpinTextX = this.getFreeSpinResultSpinTextXAnimation(100, freeSpinTextPosX);
    const addFreeSpinsResultTextXScale = this.getFreeSpinResultTextScaleXAnimation(100);
    const addFreeSpinsResultTextYScale = this.getFreeSpinResultTextScaleYAnimation(100);
    const addFreeSpinsResultSpinXTextXScale = this.getFreeSpinResultSpinTextScaleXAnimation(100);
    const addFreeSpinsResultSpinYTextXScale = this.getFreeSpinResultSpinTextScaleYAnimation(100);
    addFreeSpinsAnimationGroup.addAnimation(addFreeSpinsResultTextY);
    addFreeSpinsAnimationGroup.addAnimation(addFreeSpinsResultTextX);
    addFreeSpinsAnimationGroup.addAnimation(addFreeSpinsResultSpinTextY);
    addFreeSpinsAnimationGroup.addAnimation(addFreeSpinsResultSpinTextX);
    addFreeSpinsAnimationGroup.addAnimation(addFreeSpinsResultTextXScale);
    addFreeSpinsAnimationGroup.addAnimation(addFreeSpinsResultTextYScale);
    addFreeSpinsAnimationGroup.addAnimation(addFreeSpinsResultSpinXTextXScale);
    addFreeSpinsAnimationGroup.addAnimation(addFreeSpinsResultSpinYTextXScale);
    animationChain.appendAnimation(addFreeSpinsAnimationGroup);
    animationChain.start();

    setTimeout(() => {
      eventManager.emit(EventTypes.HANDLE_FREE_SPINS_TEXT_MOVE_END);
    }, 200);

    setTimeout(() => {
      this.resultText.visible = false;
      this.resultSpinText.visible = false;
      this.resultText.scale.set(1);
      this.resultSpinText.scale.set(1);
    }, 300);
  }

  private getPlaySongs(ninjaAttackType: NinjaAttackTypes): string {
    let song = ISongs.Challenge_Miss;

    if (ninjaAttackType === NinjaAttackTypes.NINJA_SUCCESS) {
      song = ISongs.Challenge_Miss;
    } else {
      song = ISongs.Challenge_Success;
    }

    return song;
  }

  private ninjaAttack(ninjaNo: NinjaIndex) {
    this.setResultFlg = false;
    this.cutinBaseAnimPlayNum = 0;
    setTimeout(() => {
      this.targetNinja = ninjaNo;
      this.cutinBaseAnimation!.getSpine().visible = true;
      this.cutinBaseAnimation!.setAnimation('Cutin_Base_in', false);
      this.cutinBaseAnimation!.start();
      this.ambientNinjaAttackIntroAnimation!.getSpine().visible = true;
      this.ambientNinjaAttackIntroAnimation!.setAnimation('Cutin_Intro', false);
      this.ambientNinjaAttackIntroAnimation!.start();
      AudioHowl.play({
        type: ISongs.Challenge_Attack,
        stopPrev: false,
      });
    }, CHALLENGE_GAME_NINJA_ATTACK_DELAY);
  }

  private ninjaAttackStart(_ninjaNo: NinjaIndex) {
    if (this.setResultFlg && this.cutinBaseAnimPlayNum > 0) {
      this.setResultFlg = false;
      this.cutinBaseAnimPlayNum = 0;
      this.ambientNinjaAttackIntroAnimation!.end();
      this.ambientNinjaAttackIntroAnimation!.getSpine().visible = false;

      this.ninjaAttackIndex = 0;
      this.ninjaAnimation = NinjaAttackAnimation[this.ninjaAttackType].animationName[this.ninjaAttackIndex];

      this.ambientNinjaAttackAnimation!.getSpine().visible = true;
      this.ambientNinjaAttackAnimation!.setAnimation(this.ninjaAnimation, false);

      this.ambientNinjaAttackAnimation!.start();
    }
  }

  private getFreeSpinResultTextYAnimation(duration: number): Animation {
    const animation = new Tween({
      object: this.resultText,
      duration,
      property: TweenProperties.Y,
      propertyBeginValue: this.resultText.position.y,
      target: -60, // FREE_SPINS_COUNTER_POS_Y,
    });
    return animation;
  }

  private getFreeSpinResultTextXAnimation(duration: number, freeSpinTextPosX: number): Animation {
    const animation = new Tween({
      object: this.resultText,
      duration,
      property: TweenProperties.X,
      propertyBeginValue: this.resultText.position.x,
      target: freeSpinTextPosX,
    });
    return animation;
  }

  private getFreeSpinResultSpinTextYAnimation(duration: number): Animation {
    const animation = new Tween({
      object: this.resultSpinText,
      duration,
      property: TweenProperties.Y,
      propertyBeginValue: this.resultSpinText.position.y,
      target: -10, // FREE_SPINS_COUNTER_POS_Y,
    });
    return animation;
  }

  private getFreeSpinResultSpinTextXAnimation(duration: number, freeSpinTextPosX: number): Animation {
    const animation = new Tween({
      object: this.resultSpinText,
      duration,
      property: TweenProperties.X,
      propertyBeginValue: this.resultText.position.x,
      target: freeSpinTextPosX,
    });
    return animation;
  }

  private getFreeSpinResultTextScaleXAnimation(duration: number): Animation {
    const animation = new Tween({
      object: this.resultText.scale,
      duration,
      propertyBeginValue: 1,
      target: FREE_SPINS_TITLE_MOVE_SCALE,
      property: TweenProperties.X,
    });
    return animation;
  }

  private getFreeSpinResultTextScaleYAnimation(duration: number): Animation {
    const animation = new Tween({
      object: this.resultText.scale,
      duration,
      propertyBeginValue: 1,
      target: FREE_SPINS_TITLE_MOVE_SCALE,
      property: TweenProperties.Y,
    });
    return animation;
  }

  private getFreeSpinResultSpinTextScaleXAnimation(duration: number): Animation {
    const animation = new Tween({
      object: this.resultSpinText.scale,
      duration,
      propertyBeginValue: 1,
      target: FREE_SPINS_TITLE_MOVE_SPIN_SCALE,
      property: TweenProperties.X,
    });
    return animation;
  }

  private getFreeSpinResultSpinTextScaleYAnimation(duration: number): Animation {
    const animation = new Tween({
      object: this.resultSpinText.scale,
      duration,
      propertyBeginValue: 1,
      target: FREE_SPINS_TITLE_MOVE_SPIN_SCALE,
      property: TweenProperties.Y,
    });
    return animation;
  }

  private onChangeMode(settings: { mode: GameMode; background?: BgSkin }) {}
}
export default NinjaAttack;
