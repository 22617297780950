import { EventTypes, NinjaType } from '../../../global.d';
import ViewContainer from '../../components/container';
import { NINJA_SYMBOL_POSITION_Y, eventManager } from '../../config';

import NinjaSymbol from './ninjaSymbol';

class NinjaSymbolContainer extends ViewContainer {
  private NinjaSymbolIcon: NinjaSymbol[] = [];

  constructor() {
    super();

    for (let j = 0; j < 3; j++) {
      const sprite = new NinjaSymbol(j);
      this.NinjaSymbolIcon.push(sprite);
      this.addChild(sprite);
    }

    this.setPosition();
  }

  public resetNinjaSymbol(): void {
    for (let i = 0; i < 3; i++) {
      this.updateNinjaSymbol(i, false);
    }
  }

  private setPosition(): void {
    this.x = 0;
    this.y = NINJA_SYMBOL_POSITION_Y;
  }

  public updateNinjaSymbol(ninjyaType: NinjaType, valid: boolean): void {
    this.NinjaSymbolIcon[ninjyaType].updateNinjaSymbolIcon(valid);
  }
}

export default NinjaSymbolContainer;
