import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import { setIsChallengeModeSceneChange } from '../../gql';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import {
  SCENE_CHANGE_BACKGROUND_COLOR,
  SCENE_CHANGE_BLACK_SCREEN_ANIMATION_TIME,
  SCENE_CHANGE_BLACK_SCREEN_WAIT_TIME,
  SCENE_CHANGE_FADE_TIME,
  SCENE_CHANGE_NINJA_LANDING_PLACE,
  SCENE_CHANGE_SCALE,
  eventManager,
} from '../config';

class SceneChange extends PIXI.Container {
  private rect: PIXI.Graphics;

  public SceneChangeSize: { width: number; height: number };

  private SceneChangeScale: number;

  private gameChangeNinja: SpineAnimation | null = null;

  constructor() {
    super();

    this.visible = false;
    this.SceneChangeSize = { width: 1980, height: 1080 };
    this.SceneChangeScale = 0.5;
    this.rect = new PIXI.Graphics();
    this.rect.beginFill(SCENE_CHANGE_BACKGROUND_COLOR);
    this.rect.drawRect(0, 0, this.SceneChangeSize.width, this.SceneChangeSize.height);
    this.rect.position.set(0, 0);
    this.addChild(this.rect);

    this.gameChangeNinja = new SpineAnimation({}, PIXI.Loader.shared.resources.Gamechange_Ninja.spineData!);

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));

    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resizeScale.bind(this));

    eventManager.addListener(EventTypes.SCENE_CHANGE_DOWN, this.sceneChangeIn.bind(this));
    eventManager.addListener(EventTypes.SCENE_CHANGE_UP, this.sceneChangeOut.bind(this));
  }

  private sceneChangeIn() {
    this.visible = true;
    const animationChain: AnimationChain = new AnimationChain();
    const fadeOutGroup = new AnimationGroup();
    const sceneChangeIn = this.getSceneChangeInAnimation(SCENE_CHANGE_BLACK_SCREEN_ANIMATION_TIME);
    sceneChangeIn.addOnStart(() => {
      this.gameChangeNinja = new SpineAnimation({}, PIXI.Loader.shared.resources.Gamechange_Ninja.spineData!);
      this.gameChangeNinja
        ?.getSpine()
        .position.set(
          this.SceneChangeSize.width / 2,
          this.SceneChangeSize.height - SCENE_CHANGE_NINJA_LANDING_PLACE * this.SceneChangeScale,
        );

      this.gameChangeNinja!.setAnimation('Gamechange_Down', false);
      this.gameChangeNinja!.getSpine().scale.set(this.SceneChangeScale);
      this.rect.addChild(this.gameChangeNinja!.spine);
      AudioHowl.play({
        type: ISongs.Scene_Change,
        stopPrev: true,
      });
      setIsChallengeModeSceneChange(true);
      eventManager.emit(EventTypes.CHALLENGE_MODE_SCENE_CHANGE);

      this.gameChangeNinja!.start();
    });

    const sceneChangeOutWait = this.getFadeAnimationWait(SCENE_CHANGE_BLACK_SCREEN_WAIT_TIME);
    const fadeOutNinja = this.getFadeNinjaAnimation(0, SCENE_CHANGE_FADE_TIME);
    const fadeOut = this.getFadeAnimation(0, SCENE_CHANGE_FADE_TIME);

    animationChain.addOnComplete(() => {
      setIsChallengeModeSceneChange(false);
      eventManager.emit(EventTypes.CHALLENGE_MODE_SCENE_CHANGE);
      this.visible = false;
      this.gameChangeNinja!.spine.destroy();
      this.rect.removeChild(this.gameChangeNinja!.spine);
    });

    animationChain.appendAnimation(sceneChangeIn);
    animationChain.appendAnimation(sceneChangeOutWait);
    fadeOutGroup.addAnimation(fadeOutNinja);
    fadeOutGroup.addAnimation(fadeOut);
    animationChain.appendAnimation(fadeOutGroup);
    animationChain.start();
  }

  private sceneChangeOut() {
    this.visible = true;
    const animationChain: AnimationChain = new AnimationChain();
    const fadeIn = this.getFadeAnimation(1, SCENE_CHANGE_FADE_TIME);
    fadeIn.addOnComplete(() => {
      this.gameChangeNinja = new SpineAnimation({}, PIXI.Loader.shared.resources.Gamechange_Ninja.spineData!);
      this.gameChangeNinja
        ?.getSpine()
        .position.set(
          this.SceneChangeSize.width / 2,
          this.SceneChangeSize.height - SCENE_CHANGE_NINJA_LANDING_PLACE * this.SceneChangeScale,
        );
      this.gameChangeNinja!.setAnimation('Gamechange_Up', false);
      this.gameChangeNinja!.getSpine().scale.set(this.SceneChangeScale);
      this.rect.addChild(this.gameChangeNinja!.spine);
      setTimeout(() => {
        AudioHowl.play({
          type: ISongs.Scene_Change,
          stopPrev: true,
        });
      }, 500);
      setIsChallengeModeSceneChange(true);
      eventManager.emit(EventTypes.CHALLENGE_MODE_SCENE_CHANGE);

      this.gameChangeNinja!.start();
    });

    const sceneChangeOutWait = this.getFadeAnimationWait(SCENE_CHANGE_BLACK_SCREEN_WAIT_TIME);
    const sceneChangeOut = this.getSceneChangeOutAnimation(SCENE_CHANGE_BLACK_SCREEN_ANIMATION_TIME);
    sceneChangeOut.addOnStart(() => {});
    sceneChangeOut.addOnComplete(() => {
      setIsChallengeModeSceneChange(false);
      eventManager.emit(EventTypes.CHALLENGE_MODE_SCENE_CHANGE);

      this.visible = false;
      this.gameChangeNinja!.spine.destroy();
      this.rect.removeChild(this.gameChangeNinja!.spine);
    });

    animationChain.appendAnimation(fadeIn);
    animationChain.appendAnimation(sceneChangeOutWait);
    animationChain.appendAnimation(sceneChangeOut);
    animationChain.start();
  }

  private getSceneChangeInAnimation(duration: number): Animation {
    const animation = new Tween({
      object: this.rect,
      duration,
      property: TweenProperties.Y,
      propertyBeginValue: -this.SceneChangeSize.height,
      target: 0,
    });
    return animation;
  }

  private getSceneChangeOutAnimation(duration: number): Animation {
    const animation = new Tween({
      object: this.rect,
      duration,
      property: TweenProperties.Y,
      propertyBeginValue: 0,
      target: -this.SceneChangeSize.height,
    });
    return animation;
  }

  private getFadeAnimation(alpha: number, duration: number): Animation {
    const animation = new Tween({
      object: this.rect,
      duration,
      propertyBeginValue: alpha === 1 ? 0 : 1,
      target: alpha,
      property: TweenProperties.ALPHA,
    });
    return animation;
  }

  private getFadeNinjaAnimation(alpha: number, duration: number): Animation {
    const animation = new Tween({
      object: this.gameChangeNinja!.spine,
      duration,
      propertyBeginValue: alpha === 1 ? 0 : 1,
      target: alpha,
      property: TweenProperties.ALPHA,
    });
    return animation;
  }

  private getFadeAnimationWait(duration: number): Animation {
    const animation = new Tween({
      object: this.rect,
      duration,
      propertyBeginValue: 1,
      target: 1,
      property: TweenProperties.ALPHA,
    });
    return animation;
  }

  private resize(width: number, height: number): void {
    this.SceneChangeSize = { width, height };
    this.rect.clear();
    this.rect.beginFill(SCENE_CHANGE_BACKGROUND_COLOR);
    this.rect.drawRect(0, 0, width, height);
  }

  private resizeScale(
    width: number,
    height: number,
    x: number,
    y: number,
    scale: number,
    pivotX: number,
    pivotY: number,
  ): void {
    this.SceneChangeScale = scale * SCENE_CHANGE_SCALE;
  }
}
export default SceneChange;
