import type { RemoteStorage } from '@phoenix7dev/setting-store-client';

import { SlotId } from './config';
import { ReelSetType } from './gql/query';
import Animation from './slotMachine/animations/animation';
import { Icon, LineSet } from './slotMachine/d';

export interface IUserBalance {
  id: string;
  clientId: string;
  balance: {
    amount: number;
    currency: string;
  };
}

declare global {
  interface Window {
    __ENV__: {
      ENV: string;
      STATIC_CDN_URL: string;
      STATIC_CDN_DIRECTORY: string;
      APP_VERSION: string;
      APP_NAME: string;
      SENTRY_ENABLED: boolean;
      SENTRY_DSN: string;
      NETWORK_RETRY_ATTEMPTS: number;
      NETWORK_RETRY_DELAY: number;
    };
    __PIXI_APP__: PIXI.Application;
    eventManager: PIXI.utils.EventEmitter;
    Howler: {
      _spriteBaseUrl: string;
    };
    eventManager: PIXI.utils.EventEmitter;
    remoteStorage: RemoteStorage;
  }
}

export interface IAuthInput {
  clientId: string;
  token: string;
  slotId: string;
  lng: string;
  home: string;
}

export interface IGetBonusesInput {
  id: string;
  purchasable: boolean;
}

export interface IBuyBonusesInput {
  id: string;
  coinValue: number;
}

export interface ISettledBet {
  bet: {
    id: string;
    coinAmount: number;
    coinValue: number;
    slotId: string;
    result: {
      reelPositions: number[];
      winCoinAmount: number;
      spinResult: Icon[];
    };
    userBonusId: string;
    userBonus: {
      id: string;
      bonusId: string;
      lineSet: LineSet;
    };
    reelSetId: string;
    reelSet: ReelSet;
    lineSet: LineSet;
    data: {
      isBonus: boolean;
      bonuses: UserBonus[];
      features: {
        isFreeSpins: boolean;
      };
      totalBonusWinCoinAmount?: number;
    };
    createdAt: string;
    updatedAt: string;
  };
  paylines: {
    lineId: number;
    winPositions: number[];
    amount: number;
  }[];
  balance: {
    placed: {
      amount: number;
      currency: string;
    };
    settled: {
      amount: number;
      currency: string;
    };
  };
  rewards: BetReward[];
}
export type BetRewardType = 'BetBonusReward' | 'BetCoinReward' | 'ReplayBonusReward';
export type BetBonusReward = {
  __typename: BetRewardType;
  userBonusId: string;
  userBonus?: UserBonus;
};

export type BetCoinReward = {
  __typename: BetRewardType;
  amount: number;
};

export type BetReward = BetBonusReward | BetCoinReward;
export type ReelSet = {
  id: string;
  layout: SlotId[][];
  type: ReelSetType;
};
export type UserBonus = {
  bonusId: string;
  id: string;
  isActive: boolean;
  currentRound: number;
  betId: string;
  rounds: number;
  reelSetId: string;
  bonus: Bonus;
  totalWinAmount: number;
  coinAmount: number;
  coinValue: number;
  roundsPlayed: number;
  data: UserBonusData;
  bonus?: {
    id: string;
    rounds: number;
    reelSetId: number;
  };
};

export type UserBonusData = {
  betId: string;
  count: number;
  preLoadedGrantBreakdown: PreLoadedGrantBreakdown[];
};

export type GrantsReward = CoinGrants | BonusGrants;

export type PreLoadedGrantBreakdown = {
  grants: Grants[];
  trigger: Trigger;
};

export type Grants = {
  grants: GrantsReward[];
  type: string;
};

export type CoinGrants = {
  multiplier: number;
  rewardBasis: string;
  type: string;
};

export type BonusGrants = {
  count: number;
  id: string;
  type: string;
};

export type Trigger = {
  triggerId: string;
};

export enum ChallengeResult {
  NON,
  NINJA_C,
  NINJA_B,
  NINJA_A,
  COINS,
  FREE_SPIN,
}

export enum NinjaType {
  NON,
  NINJA_C,
  NINJA_B,
  NINJA_A,
  NINJA_MAX,
}

export enum ShurikenType {
  SHURIKEN_1st,
  SHURIKEN_2nd,
  SHURIKEN_3rd,
  SHURIKEN_MAX,
}

export interface IBonus {
  id: string;
  type: string;
  coinAmount: number;
}
export interface GetUserBonusesInput {
  id: string;
  status: BonusStatus;
}

export type FreeSpinsTitleProps = {
  text: string;
  spins: number;
  currentSpin: number;
};

export type MessageBannerProps = {
  title?: { key: string; var: string };
  titlePosition?: number;
  titleStyles?: PIXI.TextStyle;
  subtitle?: { key: string; var: string };
  subtitlePosition?: number;
  subtitleStyles?: PIXI.TextStyle;
  btnText?: string;
  additionalText?: string;
  additionalPosition?: number;
  additionalStyles?: PIXI.TextStyle;
  preventDefaultDestroy?: boolean;
  callback?: () => void;
  onInitCallback?: () => void;
};

export type BaseMessageBannerProps = {
  preventDefaultDestroy?: boolean;
  callback?: () => void;
  onInitCallback?: () => void;
};

export type MessageChallengeGameStartBannerProps = BaseMessageBannerProps & {
  title: string;
  description1: string;
  btnText: string;
};

export type MessageChallengeGameResultBannerProps = BaseMessageBannerProps & {
  title: string;
  btnText: string;
  freeSpins?: string;
  getIconPattern?: number;
};

export type MessageFreeSpinsBannerProps = BaseMessageBannerProps & {
  title: string;
  description: string;
  btnText: string;
};

export type MessageWinBannerProps = BaseMessageBannerProps & {
  title: string;
  totalWin: string;
  callback?: () => void;
  preventDefaultDestroy: boolean;
};

export type ReplayFreeSpinBets = {
  id: string;
  reelSetId: string;
};

export enum EventTypes {
  ANTICIPATION_STARTS = 'anticipationStarts',
  BREAK_SPIN_ANIMATION = 'breakSpinAnimation',
  COUNT_UP_END = 'countUpEnd',
  CHANGE_MODE = 'changeMode',
  CHANGE_REEL_SET = 'changeReelSet',
  CREATE_FREE_SPINS_TITLE = 'createFreeSpinsTitle',
  CREATE_CHALLENGE_GAME_START_MESSAGE_BANNER = 'createChallengeGameStartMessageBanner',
  CREATE_CHALLENGE_GAME_RESULT_MESSAGE_BANNER = 'createChallengeGameResultMessageBanner',
  CREATE_MESSAGE_BANNER = 'createMessageBanner',
  CREATE_WIN_MESSAGE_BANNER = 'createWinMessageBanner',
  DISABLE_ALL_MINI_PAY_TABLES = 'disableAllMiniPayTables',
  DISABLE_PAY_TABLE = 'disablePayTable',
  END_TWEEN_ANIMATION = 'endTweenAnimation',
  END_RETRIGGER_FEATURE = 'endRetriggerFeature',
  FORCE_STOP_REELS = 'forceStopReels',
  HIDE_ALL_WIN_LINES = 'hideAllWinLines',
  HIDE_WIN_LINES = 'hideWinLines',
  JINGLE_START = 'jingleStart',
  HIDE_COINS = 'hideCoins',
  HANDLE_CHANGE_RESTRICTION = 'handleChangeRestriction',
  HIDE_WIN_LABEL = 'hideWinLabel',
  HIDE_STOP_SLOTS_DISPLAY = 'hideStopSlotsDisplay',
  HIDE_WIN_COUNT_UP_MESSAGE = 'hideWinCountUpMessage',
  HANDLE_CHANGE_BET_AMOUNT = 'handleChangeBetAmount',
  HANDLE_SKIP_FADE_ANIMATION = 'handleSkipFadeAnimation',
  HANDLE_START_FADE_ANIMATION = 'handleStartFadeAnimation',
  HANDLE_BUY_BONUS = 'handleBuyBonus',
  HANDLE_UPDATE_FREE_SPINS_TITLE = 'handleUpdateFreeSpinsTitle',
  HANDLE_UPDATE_FREE_SPINS_MULTIPLIER = 'handleUpdateFreeSpinsMultiplier',
  SHOW_STOP_SLOTS_DISPLAY = 'showStopSlotsDisplay',
  SHOW_FREE_SPINS_MULTIPLIER_ANIMATION = 'showFreeSpinsMultiplierAnimation',
  HANDLE_IS_ACTIVE_FREE_SPINS_GAME = 'handleIsActiveFreeSpinsGame',
  NEXT_FREE_SPINS_ROUND = 'nextFreeSpinsRound',
  SPACE_KEY_SPIN = 'spaceKeySpin',
  SPACE_KEY_CLOSE_MESSAGE_BANNER = 'spaceKeyCloseMessageBanner',
  MANUAL_DESTROY_MESSAGE_BANNER = 'manualDestroyMessageBanner',
  MANUAL_CHANGE_BACKGROUND = 'manualChangeBackground',
  REEL_STOPPED = 'reelStopped',
  REELS_STOPPED = 'reelsStopped',
  REGISTER_ANIMATOR = 'registerAnimator',
  RESIZE = 'resize',
  RESIZE_GAME_CONTAINER = 'resizeGameContainer',
  REMOVE_TWEEN_ANIMATION = 'removeTweenAnimation',
  REMOVE_FREE_SPINS_TITLE = 'removeFreeSpinsTitle',
  REMOVE_ANIMATOR = 'removeAnimator',
  RESET_SLOT_MACHINE = 'resetSlotMachine',
  ROLLBACK_REELS = 'rollbackReels',
  SLOT_MACHINE_STATE_CHANGE = 'slotMachineStateChange',
  SET_SLOTS_VISIBILITY = 'setSlotsVisibility',
  SET_SLOTS_STOP_DISPLAY_VISIBILITY = 'setSlotsStopDisplayVisibility',
  SET_WIN_LABEL_TEXT = 'setWinLabelText',
  START_TWEEN_ANIMATION = 'startTweenAnimation',
  START_WIN_ANIMATION = 'startWinAnimation',
  START_SPIN_ANIMATION = 'startSpinAnimation',
  START_MODE_CHANGE_FADE = 'startModeChangeFade',
  START_RETRIGGER_FEATURE = 'startRetriggerFeature',
  START_BUY_FEATURE_ROUND = 'startBuyFeatureRound',
  SHOW_WIN_LINES = 'showWinLines',
  SHOW_TINT = 'showTint',
  SHOW_PAY_TABLE = 'showPayTable',
  SHOW_COINS = 'showCoins',
  SHOW_WIN_LABEL = 'showWinLabel',
  SET_BIG_WIN_VISIBILITY = 'setBigWinVisibility',
  SET_MEGA_WIN_VISIBILITY = 'setMegaWinVisibility',
  SET_GREAT_WIN_VISIBILITY = 'setGreatWinVisibility',
  SET_EPIC_WIN_VISIBILITY = 'setEpicWinVisibility',
  SETUP_REEL_POSITIONS = 'setupReelPositions',
  SKIP_ALL_WIN_ANIMATIONS = 'skipAllWinAnimations',
  SKIP_WIN_COUNT_UP_ANIMATION = 'skipWinCountUpAnimation',
  SKIP_WIN_SLOTS_ANIMATION = 'skipWinSlotsAnimation',
  THROW_ERROR = 'throwError',
  UPDATE_BET = 'updateBet',
  UPDATE_USER_BALANCE = 'updateUserBalance',
  UPDATE_TOTAL_WIN_VALUE = 'updateTotalWinValue',
  UPDATE_WIN_VALUE = 'updateWinValue',
  POST_RENDER = 'postrender',
  OPEN_BUY_FEATURE_POPUP = 'openBuyFeaturePopup',
  OPEN_BUY_FEATURE_POPUP_BG = 'openBuyFeaturePopupBg',
  OPEN_BUY_FEATURE_CONFIRM_POPUP = 'openBuyFeatureConfirmPopup',
  CLOSE_BUY_FEATURE_POPUP = 'closeBuyFeaturePopup',
  CLOSE_BUY_FEATURE_POPUP_BG = 'closeBuyFeaturePopupBg',
  CLOSE_BUY_FEATURE_CONFIRM_POPUP = 'closeBuyFeatureConfirmPopup',
  DISABLE_BUY_FEATURE_BTN = 'disableBuyFeatureBtn',
  SET_IS_LEFT_HAND_MODE = 'setIsLeftHandMode',
  SET_IS_AUTO_SPINS = 'setIsAutoSpins',
  SET_AUTO_SPINS_LEFT = 'setAutoSpinsLeft',
  SET_IS_SPIN_IN_PROGRESS = 'isSpinInProgress',
  SET_IS_SLOT_IS_BUSY = 'isSlotBusy',
  SET_IS_SLOTS_STOPPED = 'isSlotsStopped',
  SET_IS_FREESPINS_WIN = 'isFreeSpinWin',
  TOGGLE_SOUND = 'toggleSound',
  TOGGLE_TURBO_SPIN = 'toggleTurboSpin',
  TOGGLE_SPIN = 'toggleSpin',
  SOUND_INITIALIZED = 'soundInitialized',
  ENABLE_SOUND_LOADER = 'enableSoundLoader',
  HANDLE_DESTROY_INTRO_SCREEN = 'handleDestroyIntroScreen',
  SCENE_CHANGE_DOWN = 'sceneChangeDown',
  SCENE_CHANGE_UP = 'sceneChangeUp',
  HANDLE_FREE_SPINS_TEXT_MOVE_END = 'handleFreeSpinsTextMoveEnd',
  HANDLE_UPDATE_FREE_SPINS_COUNT = 'handleUpdateFreeSpinsCount',
  HANDLE_UPDATE_NINJA = 'handleUpdateNinja',
  HANDLE_UPDATE_SHURIKEN = 'handleUpdateShuriken',
  NEARMISS = 'nearmiss',
  NEARMISS_SKIP = 'nearmissSkip',
  HANDLE_RESET_SHURIKEN = 'handleResetShuriken',
  CHALLENGE_NINJA_INIT = 'challengeNinjaInit',
  CHALLENGE_NINJA_START = 'challengeNinjaStart',
  CHALLENGE_NINJA_SPIN = 'challengeNinjaSpin',
  CHALLENGE_NINJA_SPIN_RESULT = 'challengeNinjaSpinResult',
  CHALLENGE_NINJA_CLICK = 'challengeNinjaClick',
  CHALLENGE_NINJA_RESULT_LOOP = 'challengeNinjaResultLoop',
  CHALLENGE_NINJA_RESULT_DISPLAY = 'challengeNinjaResultDisplay',
  CHALLENGE_NINJA_END = 'challengeNinjaEnd',
  SET_STATE = 'setState',
  SLOT_HIGHLIGHT_START = 'slot_highlight_start',
  SLOT_HIGHLIGHT_END = 'slot_highlight_end',
  PLACE_BET_COMPLETED = 'placeBetCompleted',
  CHALLENGE_MODE_SCENE_CHANGE = 'challenge_mode_scene_change',
  UPDATE_SPIN_BUTTON = 'update_spin_button',
  FORCE_CLOSE_BUYFEATURE = 'forceCloseBuyFeature',
  SET_REPLAY_BET_ID = 'setReplayBetId',
  REPLAY_CHALLENGE_NINJA_CLICK = 'replayChallengeNinjaSpin',
}

export interface Events {
  [EventTypes.ANTICIPATION_STARTS]: (index: number) => void;
  [EventTypes.BREAK_SPIN_ANIMATION]: () => void;
  [EventTypes.COUNT_UP_END]: () => void;
  [EventTypes.CHANGE_MODE_BACKGROUND]: () => void;
  [EventTypes.CHANGE_MODE]: (settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    isRetrigger?: boolean;
  }) => void;
  [EventTypes.CHANGE_REEL_SET]: (settings: { reelSet: ReelSet; reelPositions: number[] }) => void;
  [EventTypes.CREATE_FREE_SPINS_TITLE]: (props: FreeSpinsTitleProps) => void;
  [EventTypes.CREATE_CHALLENGE_GAME_START_MESSAGE_BANNER]: (props: MessageChallengeGameStartBannerProps) => void;
  [EventTypes.CREATE_CHALLENGE_GAME_RESULT_MESSAGE_BANNER]: (props: MessageChallengeGameResultBannerProps) => void;
  [EventTypes.CREATE_MESSAGE_BANNER]: (props: MessageFreeSpinsBannerProps) => void;
  [EventTypes.CREATE_WIN_MESSAGE_BANNER]: (props: MessageWinBannerProps) => void;

  // props: MessageBannerProps & {
  //   totalWin: string;
  //   totalSpins: number;
  // },
  [EventTypes.DISABLE_ALL_MINI_PAY_TABLES]: () => void;
  [EventTypes.DISABLE_PAY_TABLE]: (isVisible: boolean) => void;
  [EventTypes.END_TWEEN_ANIMATION]: (animation: Tween) => void;
  [EventTypes.FORCE_STOP_REELS]: (isTurboSpin: boolean) => void;
  [EventTypes.END_RETRIGGER_FEATURE]: () => void;
  [EventTypes.JINGLE_START]: () => void;
  [EventTypes.HIDE_COINS]: () => void;
  [EventTypes.HANDLE_CHANGE_RESTRICTION]: () => void;
  [EventTypes.HIDE_WIN_COUNT_UP_MESSAGE]: () => void;
  [EventTypes.HIDE_WIN_LABEL]: () => void;
  [EventTypes.HIDE_STOP_SLOTS_DISPLAY]: () => void;
  [EventTypes.HANDLE_CHANGE_BET_AMOUNT]: (betAmount: number) => void;
  [EventTypes.HANDLE_SKIP_FADE_ANIMATION]: () => void;
  [EventTypes.HANDLE_START_FADE_ANIMATION]: (stage: number) => void;
  [EventTypes.HANDLE_BUY_BONUS]: (bonusId: string) => void;
  [EventTypes.NEXT_FREE_SPINS_ROUND]: () => void;
  [EventTypes.SPACE_KEY_SPIN]: () => void;
  [EventTypes.SPACE_KEY_CLOSE_MESSAGE_BANNER]: () => void;
  [EventTypes.MANUAL_DESTROY_MESSAGE_BANNER]: () => void;
  [EventTypes.MANUAL_CHANGE_BACKGROUND]: (settings: { mode: GameMode; background?: BgSkin }) => void;
  [EventTypes.REEL_STOPPED]: (reelId: number) => void;
  [EventTypes.REELS_STOPPED]: (isTurboSpin: boolean) => void;
  [EventTypes.REGISTER_ANIMATOR]: (animator: () => void, priority?: number) => void;
  [EventTypes.RESET_SLOT_MACHINE]: () => void;
  [EventTypes.RESIZE]: (width: number, height: number) => void;
  [EventTypes.RESIZE_GAME_CONTAINER]: (
    width: number,
    height: number,
    x: number,
    y: number,
    scale: number,
    pivotX: number,
    pivotY: number,
  ) => void;
  [EventTypes.REMOVE_TWEEN_ANIMATION]: (animation: Tween) => void;
  [EventTypes.REMOVE_FREE_SPINS_TITLE]: () => void;
  [EventTypes.REMOVE_ANIMATOR]: (animator: () => void) => void;
  [EventTypes.ROLLBACK_REELS]: (positions: number[]) => void;
  [EventTypes.SLOT_MACHINE_STATE_CHANGE]: (state: SlotMachineState) => void;
  [EventTypes.SET_SLOTS_VISIBILITY]: (slots: number[], visible: boolean) => void;
  [EventTypes.SET_SLOTS_STOP_DISPLAY_VISIBILITY]: (slots: number[], visible: boolean) => void;
  [EventTypes.SET_WIN_LABEL_TEXT]: () => void;
  [EventTypes.START_TWEEN_ANIMATION]: (animation: Animation) => void;
  [EventTypes.START_WIN_ANIMATION]: (nextResult: ISettledBet, isTurboSpin: boolean) => void;
  [EventTypes.START_SPIN_ANIMATION]: () => void;
  [EventTypes.START_RETRIGGER_ANIMATION]: () => void;
  [EventTypes.START_MODE_CHANGE_FADE]: (settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
  }) => void;
  [EventTypes.START_BUY_FEATURE_ROUND]: () => void;
  [EventTypes.SHOW_WIN_LINES]: (lines: IWinLine[]) => void;
  [EventTypes.HIDE_ALL_WIN_LINES]: () => void;
  [EventTypes.HIDE_WIN_LINES]: (lines: IWinLine[]) => void;
  [EventTypes.SHOW_WIN_LABEL]: () => void;
  [EventTypes.SHOW_STOP_SLOTS_DISPLAY]: (
    spinResult: Icon[],
    constructorFlg: boolean,
    reelPosition?: number,
    reelLength?: number,
    reelId?: number,
  ) => void;
  [EventTypes.SHOW_TINT]: (state: boolean) => void;
  [EventTypes.SHOW_PAY_TABLE]: (i: number) => void;
  [EventTypes.SHOW_COINS]: () => void;
  [EventTypes.SET_BIG_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_MEGA_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_GREAT_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_EPIC_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SETUP_REEL_POSITIONS]: (
    reelPositions: Array<number>,
    scatterNo: Array<number>,
    anticipationStartReelId: number,
  ) => void;
  [EventTypes.SKIP_ALL_WIN_ANIMATIONS]: () => void;
  [EventTypes.SKIP_WIN_COUNT_UP_ANIMATION]: () => void;
  [EventTypes.SKIP_WIN_SLOTS_ANIMATION]: () => void;
  [EventTypes.THROW_ERROR]: () => void;
  [EventTypes.REMOVE_ANTICIPATION_TINT]: () => void;
  [EventTypes.UPDATE_BET]: (betAmount?: number) => void;
  [EventTypes.UPDATE_USER_BALANCE]: (balance?: { currency: string; amount: number }) => void;
  [EventTypes.UPDATE_TOTAL_WIN_VALUE]: (newValue: number) => void;
  [EventTypes.UPDATE_WIN_VALUE]: (newValue: string) => void;
  [EventTypes.POST_RENDER]: () => void;
  [EventTypes.OPEN_BUY_FEATURE_POPUP]: () => void;
  [EventTypes.OPEN_BUY_FEATURE_POPUP_BG]: () => void;
  [EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP]: (totalCost: string, coinAmount: number) => void;
  [EventTypes.CLOSE_BUY_FEATURE_POPUP]: () => void;

  [EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE]: (spins: string, curr: string, immediately?: boolean) => void;
  [EventTypes.CLOSE_BUY_FEATURE_POPUP_BG]: () => void;
  [EventTypes.CLOSE_BUY_FEATURE_CONFIRM_POPUP]: () => void;
  [EventTypes.DISABLE_BUY_FEATURE_BTN]: (disable: boolean) => void;
  [EventTypes.SET_IS_LEFT_HAND_MODE]: (isLeftHandMode: boolean) => void;

  [EventTypes.SET_IS_AUTO_SPINS]: (isAutoSpins: boolean) => void;

  [EventTypes.SET_AUTO_SPINS_LEFT]: (autoSpinsLeft: number) => void;
  [EventTypes.SET_IS_SPIN_IN_PROGRESS]: (isSpinInProgress: boolean) => void;
  [EventTypes.SET_IS_SLOT_IS_BUSY]: (isSlotBusy: boolean) => void;
  [EventTypes.SET_IS_SLOTS_STOPPED]: (isSlotStopped: boolean) => void;
  [EventTypes.SET_IS_FREESPINS_WIN]: (isFreeSpinWin: boolean) => void;
  [EventTypes.TOGGLE_SOUND]: (isSoundOn: boolean) => void;
  [EventTypes.TOGGLE_TURBO_SPIN]: (isTurboSpin: boolean) => void;
  [EventTypes.TOGGLE_SPIN]: () => void;
  [EventTypes.HANDLE_DESTROY_INTRO_SCREEN]: () => void;
  [EventTypes.ENABLE_SOUND_LOADER]: () => void;
  [EventTypes.SOUND_INITIALIZED]: (isError: boolean) => void;
  [EventTypes.SHOW_FREE_SPINS_MULTIPLIER_ANIMATION]: (multiplier: number) => void;
  [EventTypes.HANDLE_IS_ACTIVE_FREE_SPINS_GAME]: (isVisible: boolean) => void;
  [EventTypes.HANDLE_UPDATE_FREE_SPINS_MULTIPLIER]: () => void;
  [EventTypes.SCENE_CHANGE_DOWN]: () => void;
  [EventTypes.SCENE_CHANGE_UP]: () => void;
  [EventTypes.HANDLE_FREE_SPINS_TEXT_MOVE_END]: () => void;
  [EventTypes.HANDLE_UPDATE_FREE_SPINS_COUNT]: (spins: number, curr: number, immediately: boolean) => void;
  [EventTypes.HANDLE_UPDATE_NINJA]: (ninja: NinjaType, valid: boolean) => void;
  [EventTypes.HANDLE_UPDATE_SHURIKEN]: (shuriken: ShurikenType, valid: boolean) => void;
  [EventTypes.NEARMISS]: () => void;
  [EventTypes.NEARMISS_SKIP]: () => void;
  [EventTypes.HANDLE_RESET_SHURIKEN]: () => void;
  [EventTypes.CHALLENGE_NINJA_INIT]: () => void;
  [EventTypes.CHALLENGE_NINJA_START]: () => void;
  [EventTypes.CHALLENGE_NINJA_SPIN]: (ninjaType: NinjaIndex) => void;
  [EventTypes.CHALLENGE_NINJA_SPIN_RESULT]: (result: ChallengeResult, num: number, freeSpins?: number) => void;
  [EventTypes.CHALLENGE_NINJA_CLICK]: (index: NinjaIndex) => void;
  [EventTypes.CHALLENGE_NINJA_RESULT_LOOP]: (index: NinjaIndex) => void;

  [EventTypes.CHALLENGE_NINJA_RESULT_DISPLAY]: (index: NinjaIndex, result: ChallengeResult, num?: number) => void;

  [EventTypes.CHALLENGE_NINJA_END]: () => void;
  [EventTypes.SET_STATE]: (state: SlotMachineState) => void;
  [EventTypes.SLOT_HIGHLIGHT_START]: () => void;
  [EventTypes.SLOT_HIGHLIGHT_END]: () => void;
  [EventTypes.PLACE_BET_COMPLETED]: () => void;
  [EventTypes.CHALLENGE_MODE_SCENE_CHANGE]: () => void;
  [EventTypes.UPDATE_SPIN_BUTTON]: () => void;
  [EventTypes.FORCE_CLOSE_BUYFEATURE]: () => void;
  [EventTypes.SET_REPLAY_BET_ID]: () => void;
  [EventTypes.REPLAY_CHALLENGE_NINJA_CLICK]: () => void;
}

export enum GraphQLErrorsType {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

export enum BonusStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SETTLED = 'SETTLED',
}

export enum FeatureTypes {
  SPECIAL_ROUND = 'SPECIAL_ROUND',
  FREE_SPIN = 'FREE_SPIN',
}

export enum ModalOpeningTypes {
  MENU = 'MENU',
  AUTOPLAY = 'AUTOPLAY',
  BET_SETTINGS = 'BET_SETTINGS',
  BUY_FEATURE_POPUP = 'BUY_FEATURE_POPUP',
  NONE = null,
}

export enum BgmSoundTypes {
  BASE = 'regular',
  CG1 = 'challengeGame1',
  CG2 = 'challengeGame2',
  CG3 = 'challengeGame3',
  FS = 'fs',
}

export enum GameMode {
  REGULAR,
  FREE_SPINS,
  FREE_SPINS_SYMBOLS_C,
  FREE_SPINS_SYMBOLS_C_B,
  FREE_SPINS_SYMBOLS_C_B_A,
  BUY_FEATURE,
  CHALLENGE_GAME,
}
export const bonusIds: Record<GameMode, string> = {
  [GameMode.CHALLENGE_GAME]: '7e270515-fa79-4a0c-b330-3ea14fbae766',
  [GameMode.FREE_SPINS]: '000ff515-2cf5-4943-ac2e-89cfad3c5f78',
  [GameMode.FREE_SPINS_SYMBOLS_C]: 'c00ff515-1cc2-478b-b9ed-a9133e919c24',
  [GameMode.FREE_SPINS_SYMBOLS_C_B]: 'bc0ff515-1798-42d4-8ec6-757f87aa7ade',
  [GameMode.FREE_SPINS_SYMBOLS_C_B_A]: 'abcff515-91e7-43b1-a2b7-15680421cc45',
  [GameMode.BUY_FEATURE]: 'bf000515-b266-44ee-ab6f-040ae15b8d7d',
};

export const reelSets: Record<GameMode, string> = {
  [GameMode.REGULAR]: '7693ba59-2e0a-4835-bcb7-ab5358437b30',
  [GameMode.FREE_SPINS]: '2994df84-9fa1-4602-97ea-31d97ff92c1f',
  [GameMode.FREE_SPINS_SYMBOLS_C]: 'aba38ec0-7200-4521-aaaa-87e4ca379b2b',
  [GameMode.FREE_SPINS_SYMBOLS_C_B]: '330867cd-320d-4713-bb87-a59b5f434bef',
  [GameMode.FREE_SPINS_SYMBOLS_C_B_A]: '36d09545-745c-4c88-bad0-26951691fd04',
  [GameMode.BUY_FEATURE]: '2ce5ee71-468d-4a38-81ac-2296e2ab00ae',
  [GameMode.CHALLENGE_GAME]: 'a0685ace-ffce-4317-82af-5a9a30c9dc3a',
};

export const lineSets: Record<GameMode, string> = {
  [GameMode.REGULAR]: '5a2b2492-bfcd-4d0b-9ab2-7352380a03c2',
  [GameMode.FREE_SPINS]: '5a2b2492-bfcd-4d0b-9ab2-7352380a03c2',
  [GameMode.FREE_SPINS_SYMBOLS_C]: '5a2b2492-bfcd-4d0b-9ab2-7352380a03c2',
  [GameMode.FREE_SPINS_SYMBOLS_C_B]: '5a2b2492-bfcd-4d0b-9ab2-7352380a03c2',
  [GameMode.FREE_SPINS_SYMBOLS_C_B_A]: '5a2b2492-bfcd-4d0b-9ab2-7352380a03c2',
  [GameMode.BUY_FEATURE]: '5a2b2492-bfcd-4d0b-9ab2-7352380a03c2',
};
